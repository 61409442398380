import {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { initializeTranslator } from "../../../../helpers/language.helper";
import { selectLanguage } from "../../../../store/appInfoSlicer";
import { deleteInvoice, downloadInvoice } from "../../Enrollments/helper";
import RoundButton from "../../../core/RoundButton/RoundButton";
import {useParams} from "react-router-dom";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InvoiceDetails = ({ invoice, closeCallback }) => {

    const language = useSelector(selectLanguage);
    const t = initializeTranslator(language);
    const { company } = useParams();

    // ---------------------------------------
    // set state values

    const [voidConfirmationIsVisible, setVoidConfirmationIsVisible] = useState(false);

    // ----------------------------------------
    // Functions

    const voidConfirmation = () => {
        setVoidConfirmationIsVisible(true);
    }

    const voidInvoice = async () => {
        const voided = await deleteInvoice(company, invoice.id);
        closeCallback()
    }

    const download = async (fileType) => {
        
        await downloadInvoice(company, invoice.id, fileType);
        closeCallback()
    }

    // ---------------------------------------
    // return component

    return (<div>
        <div className="row">
            <div className="col-6 mb-3">
            
                <RoundButton
                    onClick={() => download('pdf')}
                    width={"100%"}
                    height={"2rem"}
                    outline={false}
                    content={'Descargar PDF'}
                    />
            </div>
            <div className="col-6 mb-3">
                
                <RoundButton
                    onClick={() => download('xml')}
                    width={"100%"}
                    height={"2rem"}
                    outline={false}
                    content={'Descargar XML'}
                    />
            </div>
            <div className="col-12">
                {/* Void button */}

                { !voidConfirmationIsVisible
                    ? <RoundButton
                        onClick={voidConfirmation}
                        width={"100%"}
                        height={"2rem"}
                        outline={false}
                        content={t('voidInvoice')}
                        />
                    : null }

                {/* Modal dialog to confirm item deletion */}

                {voidConfirmationIsVisible 
                    ? <div className="row">
                            <div className="col-12 mb-3">
                            {t('voidConfirmation')}
                            </div>

                            <div className="col-6">
                                <RoundButton
                                    onClick={() => setVoidConfirmationIsVisible(false)}
                                    width={"100%"}
                                    height={"1.5rem"}
                                    outline={false}
                                    content={t('no')}
                                    />
                            </div>
                            <div className="col-6">
                                <RoundButton
                                    onClick={voidInvoice}
                                    width={"100%"}
                                    height={"1.5rem"}
                                    outline={false}
                                    content={t('yes')}
                                    />
                            </div>
                        </div>
                    : null}
            </div>
        </div>    
    </div>);
}

export default InvoiceDetails;
